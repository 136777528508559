<template>
    <base-page>
        <titlu-pagina Titlu="Vouchers report" :AdaugaVisible='false' />
        <el-card style='margin:5px 0px 5px 0px'>
            <div slot="header" class="clearfix">
                <strong> Filters </strong>
            </div>
            <div class="filtre">
                <el-form @submit.prevent.native='refresh_info()'>
                    <el-row :gutter="20">
                        <el-col :span='4'>
                            <el-form-item label='CodeUsed' >
                                <el-input v-model='Filters.CodeUsed' />
                            </el-form-item>
                        </el-col>                        
                        <el-col :md='24' >
                            <el-button type='primary' native-type='submit' @click='refresh_info()'> Aplica </el-button>
                        </el-col>
                    </el-row>
                </el-form>
                </div>
        </el-card>

        <el-table :data="Results" >
            <el-table-column prop='UserName' label='User'/>
            <el-table-column prop='CodeUsed' label='CodeUsed'/>
            <el-table-column prop='VoucherDescription' label='Description'/>
            <el-table-column prop='EventDate' label='Event date'>
                <template slot-scope="scope" >
                    {{scope.row.EventDate}}
                </template>
            </el-table-column>
            
        </el-table>
    </base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import TitluPagina from '@/widgets/TitluPagina';

export default {
    name: "vouchers_report",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'titlu-pagina': TitluPagina
    },
    data () {
        return {
            Results: [],
            base_url: '',
            Info: {},
            Filters: {CodeUsed:''},
            OrderBy: { },
            PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
        }
    },
    methods: {

        async get_info(){
            var response        = await this.post("vouchers_report/get_info" );
            this.refresh_info();
        },

        async refresh_info(){
            var response        = await this.post("vouchers_report/index", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
            this.Results        = response.Results;
            this.PaginationInfo = response.PaginationInfo;
            //
            this.select_menu_item('vouchers_report');
        },
        reset(){
            this.Filters = {
                            };
            this.refresh_info();
        },

    },
    mounted(){
        this.base_url = settings.BASE_URL;
        this.get_info();
    }
};
</script>

<style lang="less" scoped>

    .top50{
        margin-top: 20px;
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
    }

</style>
